@mixin __FF_PWA_MODE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/dt-base.scss';

.container {
  position: fixed;
  padding: 12px;
  width: 100%;
  background: $orange;
  bottom: 0;
  text-align: center;
  z-index: 999;
}
