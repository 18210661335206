@mixin __FF_PWA_MODE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../shared/scss/dt-base.scss';

.wrapper {
  position: relative;
  width: 100%;
  border-bottom: 1px solid $dt-nav-light-gray;
  background: $white;
  z-index: 10;
  max-width: 1200px;
}

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 11px 20px;
  max-width: $content-max-width-xl;

  @include media($min-sm) {
    padding: 11px 40px;
  }

  @include media($min-md) {
    padding: 18px 40px;
  }

  @include media($min-lg) {
    padding: 20px 40px;
  }

  @include media($min-xl) {
    padding: 20px 0px;
  }
}

.site-logo {
  max-width: 75px;

  @include media($min-md) {
    max-width: 120px;
  }

  @include media($min-lg) {
    max-width: 150px;
  }
}
